import React from "react";

import {
	createI18nProvider,
	CrudProvider,
	CrudResource,
	useAuthProvider,
	useDataProvider,
	useI18nCatcher,
	useI18nLanguages,
	WorkflowProvider,
} from "ra-friendsofbabba";
import { Admin, Loading, Resource, RouteWithoutLayout } from "react-admin";
import { Route } from "react-router-dom";

import * as Icons from "@material-ui/icons";

import * as Components from "./component";
import Layout from "./component/Layout";
import LoginPage from "./component/page/LoginPage";
import ProfilePage from "./component/page/ProfilePage";
import ResetPasswordPage from "./component/page/ResetPasswordPage";
import { API_URL, ENVIRONMENT } from "./config";
import { resources } from "./resources";
import theme from "./theme";

const App = () => {
	const apiUrl = API_URL;
	const languages = useI18nLanguages({ apiUrl });

	// Allow i18n to intercept and send unlocalized messages to the server.
	useI18nCatcher({ apiUrl, loading: languages?.loading });

	const dataProvider = useDataProvider({
		apiUrl,
		// List of media fields that needs to be uploaded.
		fileFields: [
			"media_collection",
			"media",
			"logo",
			"meta_image",
			"background_image",
		],
	});
	const authProvider = useAuthProvider({ apiUrl });
	if (languages?.loading) {
		return (
			<Loading
				loadingPrimary="Caricamento in corso"
				loadingSecondary="Attendere prego..."
			/>
		);
	}

	return (
		<WorkflowProvider apiUrl={apiUrl}>
			<CrudProvider apiUrl={apiUrl} components={Components}>
				<Admin
					theme={theme}
					layout={Layout}
					loginPage={LoginPage}
					customRoutes={[
						<RouteWithoutLayout
							key="reset-password"
							path="/reset-password"
							component={ResetPasswordPage}
						/>,
						<Route key="profile" path="/profile" component={ProfilePage} />,
					]}
					dataProvider={dataProvider}
					authProvider={authProvider}
					i18nProvider={createI18nProvider({
						languages: languages.data,
						locale: "it",
					})}
				>
					{resources.map((resource) => (
						<CrudResource key={resource.name} {...resource} />
					))}
					<CrudResource
						name="users"
						group="admin"
						roles={["admin"]}
						icon={Icons.AccountCircle}
					/>
					{ENVIRONMENT === "PRODUCTION" ? (
						<CrudResource
							name="language-messages"
							group="admin"
							roles={["admin"]}
							icon={Icons.FlagOutlined}
							list={null}
						/>
					) : (
						<CrudResource
							name="language-messages"
							group="admin"
							roles={["admin"]}
							icon={Icons.FlagOutlined}
						/>
					)}
					<Resource name="roles" />
					<Resource name="languages" />
				</Admin>
			</CrudProvider>
		</WorkflowProvider>
	);
};
export default App;
