import PropTypes from "prop-types";
import React from "react";
import { UrlField } from "react-admin";

const LinkField = ({ target = "_blank", onClick, ...props }) => {
	return (
		<UrlField
			target={target}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				const href = e?.target?.href || "#";
				// Per evitare il conflitto con rowClick
				if (target === "_blank") {
					window.open(href, "_blank");
				} else {
					window.location.href = href;
				}
			}}
			{...props}
		/>
	);
};

LinkField.propTypes = {
	target: PropTypes.string,
	onClick: PropTypes.func,
};

export default LinkField;
