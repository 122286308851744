import { Chip, Link, makeStyles } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import classnames from "classnames";
import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {
	sanitizeFieldRestProps,
	TextField,
	useRecordContext,
	useRedirect,
} from "react-admin";

const useStyles = makeStyles((theme) => ({
	link: {
		display: "flex",
		alignItems: "center",
	},
	chip: {},
}));

const ReferenceManyFilterField = ({
	reference,
	target,
	filterField = "ids",
	optionText = "name",
	optionSeparator = ", ",
	showIcon = true,
	emptyText = "-",
	component = "Chip",
	...props
}) => {
	const classes = useStyles();
	const { className, source, ...rest } = props;
	const record = useRecordContext(props);
	const values = get(record, source, []);
	const redirect = useRedirect();

	if (!values || values.length === 0) {
		return (
			<TextField
				{...props}
				record={{
					[source]: emptyText,
				}}
			/>
		);
	}

	const value = values
		.reduce((acc, v, i) => {
			acc.push(get(v, optionText, ""));
			return acc;
		}, [])
		.join(optionSeparator);
	const filterValues = values
		.reduce((acc, v, i) => {
			acc.push(get(v, target));
			return acc;
		}, [])
		.join(",");
	const filter = JSON.stringify({ [filterField]: filterValues });
	const url = `/${reference}?filter=${encodeURIComponent(filter)}`;
	const onClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		redirect(url);
	};

	if (component === "Link") {
		return (
			<Link
				className={classnames(className, classes.link)}
				onClick={onClick}
				variant="body2"
				{...sanitizeFieldRestProps(rest)}
			>
				{value}
				{showIcon && <ArrowRightIcon />}
			</Link>
		);
	}

	return (
		<Chip
			{...sanitizeFieldRestProps(rest)}
			className={classnames(className, classes.chip)}
			onClick={onClick}
			label={value}
			// size="small"
		/>
	);
};

ReferenceManyFilterField.propTypes = {
	reference: PropTypes.string.isRequired,
	target: PropTypes.string.isRequired,
};

export default ReferenceManyFilterField;
