import React, { useMemo } from "react";

import { Datagrid } from "ra-friendsofbabba";
import {
	BooleanField,
	ChipField,
	DateField,
	List,
	Pagination,
	TextField,
	useListContext,
	usePermissions,
} from "react-admin";

import EmployeesListExpand from "../expand/EmployeesListExpand";
import { BoldField, FreeWeeklyHours } from "../field";
import { EmployeesFilter, EmployeesSidebarFilter } from "../filter";

const Grid = (props) => {
	const { filterValues } = useListContext();
	const { permissions } = usePermissions();
	const isCv = useMemo(() => permissions && permissions("cv"), [permissions]);
	const isAdmin = useMemo(
		() => permissions && permissions("admin"),
		[permissions]
	);
	const isEmployee = useMemo(
		() => permissions && permissions("employee"),
		[permissions]
	);

	return (
		<Datagrid {...props} rowClick="edit" expand={<EmployeesListExpand />}>
			<BoldField source="fullname" sortBy="firstname" />
			<TextField
				source="city.fullname"
				sortBy="Cities.name"
				label="resources.employees.fields.city_id"
			/>
			<ChipField
				source="employee_type.name"
				sortBy="EmployeeTypes.name"
				label="resources.employees.fields.employee_type_id"
			/>
			{isAdmin && filterValues.employee_type_id === 2 && (
				<BooleanField source="has_interview" />
			)}
			{isCv && <BooleanField source="has_interview" />}
			{filterValues.employee_type_id === 1 && (
				<BooleanField source="is_member" />
			)}
			{isAdmin && filterValues.employee_type_id === 1 && (
				<FreeWeeklyHours source="free_weekly_hours" />
			)}
			{isEmployee && <FreeWeeklyHours source="free_weekly_hours" />}
			<DateField source="modified" showTime />
		</Datagrid>
	);
};

const EmployeesList = (props) => {
	const { permissions } = usePermissions();
	const isCv = useMemo(() => permissions && permissions("cv"), [permissions]);
	return (
		<List
			{...props}
			aside={!isCv && <EmployeesSidebarFilter />}
			exporter={false}
			sort={{ field: "modified", order: "DESC" }}
			filters={<EmployeesFilter />}
			bulkActionButtons={true}
			perPage={50}
			pagination={<Pagination rowsPerPageOptions={[20, 50, 100, 200]} />}
		>
			<Grid />
		</List>
	);
};

export default EmployeesList;
