import FileCopy from "@material-ui/icons/FileCopy";
import React from "react";
import { Button, useNotify } from "react-admin";
import CopyToClipboard from "react-copy-to-clipboard";

const LinkClipboardTextField = (props) => {
	const notify = useNotify();
	const values = props.record;
	const value = values?.[props.source];
	const baseUrl = values?.[props.baseUrlField];
	const urlExtension = values?.[props.urlExtensionField] || "";
	const copiableValue = `${baseUrl || "#"}${value}${urlExtension}`;

	return (
		<CopyToClipboard
			text={copiableValue}
			onCopy={() => notify("ra.action.content_copied")}
			disabled={!copiableValue}
		>
			<Button label="ra.action.copy_link">
				<FileCopy />
			</Button>
		</CopyToClipboard>
	);
};

export default LinkClipboardTextField;
