import { makeStyles } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { ChipField, useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
	active: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.success.contrastText,
		fontWeight: "bold",
	},
	ending: {
		backgroundColor: theme.palette.warning.main,
		color: theme.palette.warning.contrastText,
		fontWeight: "bold",
	},
	end: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.error.contrastText,
		fontWeight: "bold",
	},
	undefined: {},
}));

const AttachmentStatusField = (props) => {
	const value = get(props?.record || {}, props?.source);
	const translate = useTranslate();
	return (
		<ChipField
			{...props}
			className={useStyles()[value || "undefined"]}
			record={{
				...props?.record,
				[props?.source]: translate(
					`resources.attachments.fields.status.${value}`
				),
			}}
		/>
	);
};

AttachmentStatusField.propTypes = ChipField.propTypes;
AttachmentStatusField.defaultProps = ChipField.defaultProps;

export default AttachmentStatusField;
