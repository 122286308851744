import React, { useMemo } from "react";

import { ReferenceAutocompleteInput } from "ra-friendsofbabba";
import { useFormState } from "react-final-form";

const ReferenceAutocompleteWithDepsInput = ({ deps, ...props }) => {
  const { values } = useFormState({ subscription: { values: true } });

  const filter = useMemo(() => {
    return deps.reduce((acc, dep) => {
      if (values[dep]) {
        acc[dep] = values[dep];
      }
      return acc;
    }, {});
  }, [deps, values]);
  if (Object.keys(filter).length === 0) return null;
  return <ReferenceAutocompleteInput {...props} filter={filter} />;
};
export default ReferenceAutocompleteWithDepsInput;
