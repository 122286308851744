import { Box, List, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: "bold",
	},
}));

const FilterList = ({ label, icon, children }) => {
	const translate = useTranslate();
	const classes = useStyles();
	return (
		<>
			<Box pl={1} display="flex" alignItems="center">
				<Box mr={1}>{icon}</Box>
				<Typography variant="overline" className={classes.title}>
					{translate(label)}
				</Typography>
			</Box>
			<List dense disablePadding>
				{children}
			</List>
		</>
	);
};

export default FilterList;
