import cloneDeep from "lodash/cloneDeep";
import React, { useMemo } from "react";
import { CloneButton as RaCloneButton } from "react-admin";

function cloneRecordWitouthExcludedFields(obj, excludedProperties) {
	Object.keys(excludedProperties).forEach((propToExcludeKey) => {
		const propToExclude = excludedProperties[propToExcludeKey];
		if (Array.isArray(propToExclude) && Array.isArray(obj[propToExcludeKey])) {
			obj[propToExcludeKey].map((elem) => {
				return cloneRecordWitouthExcludedFields(elem, propToExclude);
			});
		} else {
			delete obj[propToExclude];
		}
	});
	return obj;
}

const CloneButton = ({
	record,
	excludeFields = {},
	suffixFields = {},
	...props
}) => {
	const cloneRecord = useMemo(() => {
		let _clone = cloneDeep(record);
		let clone = cloneRecordWitouthExcludedFields(_clone, excludeFields);
		Object.keys(suffixFields).forEach((field) => {
			_clone[field] = _clone[field] + suffixFields[field];
		});
		return clone;
	}, [record, excludeFields, suffixFields]);
	// console.log("Record: ", record?.bookmaker_table_items?.[0]?.table_id);
	// console.log("Clone: ", cloneRecord?.bookmaker_table_items?.[0]?.table_id);
	return <RaCloneButton record={cloneRecord} {...props} />;
};

export default CloneButton;
