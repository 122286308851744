import { CrudContext, Menu as FobMenu } from "ra-friendsofbabba";
import React, { useContext, useMemo } from "react";

const Menu = (props) => {
	const { data } = useContext(CrudContext);
	const badges = useMemo(
		() =>
			data
				? Object.keys(data).reduce(
						(badges, k) =>
							data[k].badge != null
								? { ...badges, [k]: data[k].badge }
								: badges,
						{}
				  )
				: {},
		[data]
	);

	return (
		<FobMenu
			{...props}
			open={true}
			order={{
				dashboard: 0,
				stats: 0,
				employees: 10,
				deadlines: 20,
				jobs: 40,
				contracts: 45,
				knowledge: 50,
				attachments: 60,
				other: 70,
				admin: 200,
			}}
			badges={badges}
		/>
	);
};

export default Menu;
