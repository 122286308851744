import * as React from 'react';
import { useMemo } from 'react';

import {
	useListContext,
	usePermissions,
} from 'react-admin';

import {
	Box,
	Card as MuiCard,
	Divider as MuiDivider,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import FilterList from './filter-list/FilterList';
import FilterListItem from './filter-list/FilterListItem';
import ReferenceFilterList from './filter-list/ReferenceFilterList';

const Card = withStyles((theme) => ({
	root: {
		[theme.breakpoints.up("md")]: {
			order: -1, // display on the left rather than on the right of the list
			width: "15em",
			marginRight: "1em",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			minWidth: 230,
		},
		[theme.breakpoints.down("md")]: {
			display: "none",
		},
	},
}))(MuiCard);

const Divider = withStyles((theme) => ({
	root: {
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	},
}))(MuiDivider);

const EmployeesSidebarFilter = () => {
	const { filterValues } = useListContext();
	const { permissions } = usePermissions();
	const hasInterview =
		filterValues?.employee_type_id === 2 ||
		filterValues?.has_interview !== undefined;
	const hasIsMember =
		filterValues?.employee_type_id === 1 ||
		filterValues?.is_member !== undefined;
	const hasContract =
		filterValues?.has_contract !== undefined ||
		filterValues?.has_contract_job !== undefined ||
		filterValues?.has_contract_type !== undefined;
	// const hasJob =
	// 	filterValues?.has_job !== undefined ||
	// 	filterValues?.has_job_job_role !== undefined ||
	// 	filterValues?.has_job_workplace !== undefined ||
	// 	filterValues?.has_job_service !== undefined;
	const isEmployee = useMemo(
		() => permissions && permissions("employee"),
		[permissions]
	);

	return (
		<Card>
			<Box style={{ maxHeight: "80vh", overflow: "auto" }}>
				{!isEmployee && (
					<ReferenceFilterList
						resource="employee-types"
						label="resources.employees.fields.employee_type_id"
						target="employee_type_id"
					/>
				)}
				{hasInterview && (
					<FilterList label="resources.employees.fields.has_interview">
						<FilterListItem
							label="ra.action.yes"
							value={{
								has_interview: true,
							}}
						/>
						<FilterListItem
							label="ra.action.no"
							value={{
								has_interview: false,
							}}
						/>
					</FilterList>
				)}
				{hasIsMember && (
					<FilterList label="resources.employees.fields.is_member">
						<FilterListItem
							label="ra.action.yes"
							value={{
								is_member: true,
							}}
						/>
						<FilterListItem
							label="ra.action.no"
							value={{
								is_member: false,
							}}
						/>
					</FilterList>
				)}
				<Divider />
				<ReferenceFilterList
					resource="contracts"
					label="menu.items.contracts"
					target="has_contract"
				/>
				{hasContract && (
					<>
						<ReferenceFilterList
							resource="jobs"
							label="resources.employee-contracts.fields.job_id"
							target="has_contract_job"
						/>
						<ReferenceFilterList
							resource="contract-types"
							label="menu.items.contract-types"
							target="has_contract_type"
						/>
					</>
				)}
				{/* <Divider />
				{hasJob && (
					<>
						<ReferenceFilterList
							resource="job-roles"
							label="menu.items.job-roles"
							target="has_job_job_role"
						/>
						<ReferenceFilterList
							resource="workplaces"
							label="menu.items.workplaces"
							target="has_job_workplace"
							optionText="fullname"
						/>
						<ReferenceFilterList
							resource="services"
							label="menu.items.services"
							target="has_job_service"
						/>
					</>
				)} */}
				{/* <Divider />
				<ReferenceFilterList
					resource="certificates"
					label="menu.items.certificates"
					target="has_certificate"
				/> */}
				<Divider />
				<ReferenceFilterList
					resource="driving-licenses"
					label="menu.items.driving-licenses"
					target="driving_license_id"
				/>
				<FilterList label="resources.employees.fields.has_car">
					<FilterListItem
						label="ra.action.yes"
						value={{
							has_car: true,
						}}
					/>
					<FilterListItem
						label="ra.action.no"
						value={{
							has_car: false,
						}}
					/>
				</FilterList>
				<FilterList label="resources.employees.fields.travel_availability">
					<FilterListItem
						label="ra.action.yes"
						value={{
							travel_availability: true,
						}}
					/>
					<FilterListItem
						label="ra.action.no"
						value={{
							travel_availability: false,
						}}
					/>
				</FilterList>
			</Box>
		</Card>
	);
};

export default EmployeesSidebarFilter;
