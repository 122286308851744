import { makeStyles } from "@material-ui/core/styles";
import RichTextInput from "ra-input-rich-text";
import React from "react";

// Generate list of 15 colors in hash format
const colors = [
	"#6610f2",
	"#6f42c1",
	"#d63384",
	"#fd7e14",
	"#20c997",
	"#000",
	"#fff",
	"#e9ecef",
	"#dee2e6",
	"#ced4da",
	"#adb5bd",
	"#343a40",
	"#0d6efd",
	"#198754",
	"#0dcaf0",
	"#ffc107",
	"#dc3545",
	"#212529",
	"#0a58ca",
	"#146c43",
	"#087990",
	"#997404",
	"#b02a37",
	"#6c757d",
	"#495057",
	"#cfe2ff",
	"#f8f9fa",
	"#d1e7dd",
];

// colors.sort((a, b) => {
// 	const lumaA =
// 		(0.2126 * parseInt(a.substr(1, 2), 16)) / 255 +
// 		(0.7152 * parseInt(a.substr(3, 2), 16)) / 255 +
// 		(0.0722 * parseInt(a.substr(5, 2), 16)) / 255;
// 	const lumaB =
// 		(0.2126 * parseInt(b.substr(1, 2), 16)) / 255 +
// 		(0.7152 * parseInt(b.substr(3, 2), 16)) / 255 +
// 		(0.0722 * parseInt(b.substr(5, 2), 16)) / 255;
// 	return lumaB - lumaA;
// });

// order colors from light to dark
colors.sort((a, b) => {
	const lumaA =
		(0.2126 * parseInt(a.substr(1, 2), 16)) / 255 +
		(0.7152 * parseInt(a.substr(3, 2), 16)) / 255 +
		(0.0722 * parseInt(a.substr(5, 2), 16)) / 255;
	const lumaB =
		(0.2126 * parseInt(b.substr(1, 2), 16)) / 255 +
		(0.7152 * parseInt(b.substr(3, 2), 16)) / 255 +
		(0.0722 * parseInt(b.substr(5, 2), 16)) / 255;
	return lumaA - lumaB;
});

const configureQuill = (quill) => {
	quill.getModule("toolbar").addHandler("bold", function (value) {
		this.quill.format("bold", value);
	});
};

const useStyles = makeStyles((theme) => ({
	container: {
		"& .ql-container": {
			borderRadius: "4px",
			overflow: "hidden",
			borderColor: theme.palette.primary.main,
			backgroundColor: theme.palette.background.divider,
			borderSize: "1px",
			borderStyle: "solid",
		},
		"& .ql-editor": {
			minHeight: "70px",
		},
		"& .ql-picker-options": {
			zIndex: 10,
		},
		"& .ra-rich-text-input .ql-editor": {
			backgroundColor: "transparent",
		},
		"& .ra-rich-text-input .ql-editor::before": {
			backgroundColor: "transparent",
			height: 0,
		},
		"& .ra-rich-text-input .ql-editor:hover::before": {
			backgroundColor: "transparent",
			height: 0,
		},
		"& .ra-rich-text-input .ql-toolbar.ql-snow + .ql-container.ql-snow": {
			border: "1px solid #000",
			borderColor: theme.palette.grey[400],
		},
	},
}));

const WysiwygInput = (props) => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<RichTextInput
				fullWidth
				configureQuill={configureQuill}
				{...props}
				rows={5}
				toolbar={[
					[{ header: [1, 2, 3, 4, 5, 6, false] }],
					[
						"bold",
						"italic",
						"underline",
						"link",
						{
							color: colors,
						},
						{ background: colors },
					],
					[{ align: [] }, { list: "ordered" }, { list: "bullet" }],
				]}
				variant="filled"
			/>
		</div>
	);
};

export default WysiwygInput;
