import {
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	makeStyles,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IconCancel from "@material-ui/icons/Cancel";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
	AutocompleteArrayInput,
	Button,
	IconButtonWithTooltip,
	ReferenceArrayInput,
	SaveButton,
	SimpleForm,
	Toolbar,
	useCreate,
	useNotify,
	useTranslate,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
	},
	createContainer: {
		height: "100%",
		position: "absolute",
		display: "flex",
		alignItems: "center",
		right: theme.spacing(0.5),
		top: 0,
	},
	create: {
		// position: "absolute",
		// right: theme.spacing(1),
	},
	dialogTitle: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	dialogContent: {
		padding: 0,
	},
	toolbar: {
		display: "flex",
		justifyContent: "space-between",
	},
	cancelButton: {
		color: theme.palette.error.main,
	},
}));

const CancelButton = (props) => {
	const translate = useTranslate();
	const classes = useStyles();
	return (
		<Button
			label={translate("ra.action.cancel")}
			{...props}
			className={classes.cancelButton}
		>
			<IconCancel />
		</Button>
	);
};

const ResourceQuickCreateButtonToolbar = ({ disabled, onCancel, ...props }) => {
	const classes = useStyles();
	return (
		<Toolbar {...props} className={classes.toolbar}>
			<SaveButton submitOnEnter={true} disabled={disabled} />
			<CancelButton onClick={onCancel} disabled={disabled} />
		</Toolbar>
	);
};

const ResourceQuickCreateButton = ({
	children,
	resource,
	source,
	icon: Icon,
	...props
}) => {
	const [showDialog, setShowDialog] = useState(false);
	const [create, { loading }] = useCreate(resource);
	const notify = useNotify();
	const form = useForm();
	const formState = useFormState();
	const classes = useStyles();
	const translate = useTranslate();

	const handleClick = () => {
		setShowDialog(true);
	};

	const handleCloseClick = () => {
		setShowDialog(false);
	};

	const handleSubmit = async (values) => {
		// filter object values and get only item key that start with __qualifications__
		const targetKey = `_${resource}__`;
		const filteredValues = Object.keys(values).reduce((acc, key) => {
			if (key.startsWith(targetKey)) {
				acc[key.replace(targetKey, "")] = values[key];
			}
			return acc;
		}, {});

		create(
			{ payload: { data: filteredValues } },
			{
				onSuccess: ({ data }) => {
					setShowDialog(false);
					// Update the comment form to target the newly created post
					// Updating the ReferenceInput value will force it to reload the available posts
					const targetValue = formState?.values?.[source] || [];
					// is array targetValue
					if (Array.isArray(targetValue)) {
						form.change(source, [...targetValue, data.id]);
					} else {
						form.change(source, data.id);
					}
				},
				onFailure: ({ error }) => {
					notify(error.message, "error");
				},
			}
		);
	};

	return (
		<>
			<div className={classes.createContainer}>
				<IconButtonWithTooltip
					onClick={handleClick}
					className={classes.create}
					label={translate(`resources.${resource}.quick_create_title`, {
						_: `resources.${resource}.quick_create_title`,
					})}
				>
					<Icon />
				</IconButtonWithTooltip>
			</div>
			<Dialog fullWidth open={showDialog} onClose={handleCloseClick}>
				<DialogTitle className={classes.dialogTitle}>
					{translate(`resources.${resource}.quick_create_title`, {
						_: `resources.${resource}.quick_create_title`,
					})}
				</DialogTitle>
				<Divider />
				<DialogContent className={classes.dialogContent}>
					<SimpleForm
						variant="outlined"
						resource={resource}
						// We override the redux-form onSubmit prop to handle the submission ourselves
						save={handleSubmit}
						// We want no toolbar at all as we have our modal actions
						toolbar={
							<ResourceQuickCreateButtonToolbar
								onCancel={handleCloseClick}
								disabled={loading}
							/>
						}
					>
						{children}
					</SimpleForm>
				</DialogContent>
			</Dialog>
		</>
	);
};

const AwesomeReferenceArrayInput = ({
	optionText = "name",
	optionValue = "id",
	children,
	icon,
	...props
}) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<ReferenceArrayInput {...props}>
				<AutocompleteArrayInput
					optionText={optionText}
					optionValue={optionValue}
				/>
			</ReferenceArrayInput>
			<ResourceQuickCreateButton
				{...props}
				resource={props?.reference}
				icon={icon}
			>
				{React.Children.map(children, (child) =>
					React.cloneElement(child, {
						label: `resources.${props?.reference}.fields.${child.props.source}`,
						...child.props,
						source: `_${props?.reference}__${child.props.source}`,
					})
				)}
			</ResourceQuickCreateButton>
		</div>
	);
};

AwesomeReferenceArrayInput.propTypes = {
	...ReferenceArrayInput.propTypes,
	children: PropTypes.node,
	icon: PropTypes.element,
};

AwesomeReferenceArrayInput.defaultProps = {
	...ReferenceArrayInput.defaultProps,
	icon: AddCircleIcon,
};

export default AwesomeReferenceArrayInput;
