let environment = "PRODUCTION";
let appUrl = `//${document.location.host}/`;
if (appUrl.endsWith(":3000/")) {
	// appUrl = "https://anagrafica.ilpuzzle.org.ddev.site/";
	appUrl = "https://anagrafica.ilpuzzle.org/";
	environment = "DEVELOPER";
}
export const APP_URL = appUrl;
export const API_URL = `${APP_URL}api`;
export const DRAWER_WIDTH = 270;
export const ENVIRONMENT = environment;
export const RECAPTCHA_SITE_KEY = "?";
