import React, { useMemo } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { get } from "lodash";
import classnames from "classnames";

function getProjectName(user) {
  var userSplitted = user.split(" - ");
  var projectName = userSplitted[2]
    ? userSplitted[1] + " - " + userSplitted[2]
    : userSplitted[1];

  return projectName;
}

const useStyles = makeStyles((theme) => ({
  subLabel: {
    marginLeft: theme.spacing(2),
  },
}));

const LabelField = ({ record, source }) => {
  const classes = useStyles();
  const { label, subLabel } = useMemo(() => {
    const subLabel = get(record, "sub", false);
    const user = get(record, source, null);
    const label = subLabel ? getProjectName(user) : user;
    return { label, subLabel };
  }, [record, source]);

  return (
    <Typography
      className={classnames(subLabel && classes.subLabel)}
      variant={subLabel ? "caption" : "body2"}
    >
      {label}
    </Typography>
  );
};
export default LabelField;
