import { Divider, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
	separator: {
		marginTop: theme.spacing(0),
		marginBottom: theme.spacing(1.5),
		marginLeft: "auto",
		marginRight: "auto",
	},
	title: {
		fontWeight: "bold",
		marginTop: theme.spacing(2.5),
		marginBottom: theme.spacing(0),
	},
}));

const TextFormDivider = ({ label, ...props }) => {
	const translate = useTranslate();
	const classes = useStyles();
	return (
		<>
			<Typography
				variant="overline"
				component="p"
				gutterBottom
				className={classes.title}
				{...props}
			>
				{translate(label, { _: label })}
			</Typography>
			<Divider className={classes.separator} />
		</>
	);
};

export default TextFormDivider;
