import React, { useEffect, useState } from "react";

import { Switch } from "@material-ui/core";
import { get } from "lodash";
import { useUpdate } from "react-admin";

const IsNotifiableInput = ({ source, record }) => {
  const [checked, setChecked] = useState(get(record, source));
  const [update, { loading }] = useUpdate();
  useEffect(() => {
    setChecked(record.is_notifiable);
  }, [record.is_notifiable]);
  const handleChange = () => {
    update(
      {
        resource: "users-projects",
        payload: {
          id: record.id,
          data: {
            ...record,
            is_notifiable: !checked,
          },
          previousData: record,
        },
      },
      {
        onSuccess: () => setChecked(!checked),
      }
    );
  };
  return (
    <Switch disabled={loading} checked={checked} onChange={handleChange} />
  );
};

export default IsNotifiableInput;
