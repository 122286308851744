// import "prismjs/components/prism-clike";
// import "prismjs/components/prism-json";
// import "prismjs/components/prism-markup-templating";
// import "prismjs/components/prism-php";
// import "prismjs/components/prism-php-extras";
// import "prismjs/components/prism-sql";

import "prismjs/themes/prism.css";

import { FieldTitle, useInput } from "react-admin";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";

import Editor from "react-simple-code-editor";
import { InputHelperText } from "ra-ui-materialui";
import Prism from "prismjs";
import classNames from "classnames";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    backgroundColor: "#E8E8E8",
    borderBottom: "1px solid rgba(0,0,0,.87)",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    "& > div": {
      marginLeft: -4,
    },
    "& textarea:focus": {
      outline: "none",
    },
  },
  formControlError: {
    borderBottomStyle: "solid",
    borderBottomWidth: 2,
    borderBottomColor: red[500],
  },
  formControlLabel: {
    zIndex: 2000,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  formControlLabelError: {
    color: red[500],
  },
  formControlHelp: {
    paddingLeft: theme.spacing(1),
  },
}));

const CodeInput = ({
  source,
  label,
  validate,
  resource,
  basePath,
  helperText = null,
  type = "html",
  margin = "dense",
  defaultValue = "",
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const {
    isRequired,
    input: { value, onChange },
    meta: { touched, error },
  } = useInput({ source, validate, ...props });
  const { highlight, languages } = Prism;
  const classes = useStyles();
  return (
    <>
      <FormControl
        {...props}
        margin={margin}
        className={classNames(
          classes.formControl,
          touched && !!error ? classes.formControlError : null
        )}
      >
        <InputLabel
          focused={focus}
          shrink
          className={classNames(
            classes.formControlLabel,
            touched && !!error ? classes.formControlLabelError : null
          )}
          htmlFor={source}
        >
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
        <Editor
          id={source}
          value={value || defaultValue}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onValueChange={(code) => onChange(code !== null ? code : "")}
          highlight={(code) =>
            highlight(code !== null ? code : "", languages[type])
          }
          padding={16}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 14,
            tabSize: 4,
            marginTop: 16,
            paddingLeft: -2,
          }}
        />
      </FormControl>

      <FormHelperText
        error={!!error}
        style={{ marginLeft: 16, marginBottom: 8 }}
      >
        <InputHelperText
          error={error}
          helperText={helperText}
          touched={touched}
        />
      </FormHelperText>
    </>
  );
};

export default CodeInput;
