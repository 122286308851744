import React from "react";
import { SelectInput } from "react-admin";

const CertificateStatusInput = (props) => {
	return (
		<SelectInput
			{...props}
			emptyText="ra.action.all"
			choices={[
				{
					id: "active",
					name: "resources.employee-certificates.fields.status.active",
				},
				{
					id: "ending",
					name: "resources.employee-certificates.fields.status.ending",
				},
				{
					id: "end",
					name: "resources.employee-certificates.fields.status.end",
				},
			]}
		/>
	);
};

CertificateStatusInput.defaultProps = SelectInput.defaultProps;
CertificateStatusInput.propTypes = SelectInput.propTypes;

export default CertificateStatusInput;
