import { get } from "lodash";
import moment from "moment";
import { useMemo } from "react";

const useDayInfo = ({ record, source }) => {
  const { holiday, weekday, isWeekend } = useMemo(() => {
    const holiday = get(record, `${source}_holiday`, 0);
    const date = `${get(record, "year")}-${get(
      record,
      "month"
    )}-${source.replace("day", "")}`;
    const weekday = moment(date, "YYYY-MM-DD").isoWeekday();
    const isWeekend = weekday > 5;

    return { holiday, weekday, isWeekend };
  }, [record, source]);

  return { holiday, weekday, isWeekend };
};
export default useDayInfo;
