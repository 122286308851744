import { Datagrid } from "ra-friendsofbabba";
import React from "react";
import {
	AutocompleteInput,
	ChipField,
	DateField,
	Filter,
	List,
	Pagination,
	ReferenceInput,
	SearchInput,
	SelectInput,
} from "react-admin";
import DownloadButton from "../button/DownloadButton";
import { AttachmentStatusField, BoldField, ReferenceLinkField } from "../field";
import { ContractStatusInput } from "../input";

const Grid = (props) => {
	return (
		<Datagrid {...props}>
			<DownloadButton />
			<ReferenceLinkField
				basePath="employees"
				target="employee_id"
				source="employee.fullname"
				label="resources.attachments.fields.employee_id"
				sortBy="Employees.firstname"
			/>
			<BoldField source="name" label="resources.attachments.fields.name" />
			<ChipField
				source="attachment_type.name"
				label="resources.attachments.fields.attachment_type_id"
				sortBy="AttachmentTypes.name"
			/>
			<AttachmentStatusField
				source="status"
				label="resources.attachments.fields.status"
			/>
			<DateField source="from_date" />
			<DateField source="due_date" emptyText="-" />
		</Datagrid>
	);
};

const AttachmentsList = (props) => {
	return (
		<List
			{...props}
			exporter={false}
			sort={{ field: "due_date", order: "ASC" }}
			filters={
				<Filter variant="outlined">
					<SearchInput source="q" alwaysOn />
					<ReferenceInput
						source="employee_id"
						reference="employees"
						alwaysOn
						label="resources.attachments.fields.employee_id"
						perPage={20}
						sort={{
							field: "firstname",
							order: "ASC",
						}}
					>
						<AutocompleteInput optionText="fullname" resettable nullable />
					</ReferenceInput>
					<ReferenceInput
						source="attachment_type_id"
						reference="attachment-types"
						alwaysOn
						label="resources.attachments.fields.attachment_type_id"
						perPage={100}
						sort={{
							field: "name",
							order: "ASC",
						}}
						emptyText="ra.action.all"
						style={{ minWidth: 210 }}
					>
						<SelectInput />
					</ReferenceInput>
					<ContractStatusInput source="status" alwaysOn />
				</Filter>
			}
			bulkActionButtons={false}
			perPage={50}
			pagination={<Pagination rowsPerPageOptions={[20, 50, 100, 200]} />}
			hasCreate={false}
		>
			<Grid />
		</List>
	);
};

export default AttachmentsList;
