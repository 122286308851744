import { Button } from "react-admin";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import React from "react";
import { generatePath } from "react-router";

const ActivityEditButton = ({ record, disabled }) => {
  const path = generatePath("/:basePath/:id/?source=:ticketId", {
    basePath: "activities",
    id: record?.id,
    ticketId: JSON.stringify({
      ticket_id: record?.ticket_id,
    }),
  });
  return (
    <Button
      label="ra.action.edit"
      to={path}
      component={Link}
      disabled={disabled}
    >
      <EditIcon />
    </Button>
  );
};
export default ActivityEditButton;
