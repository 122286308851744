import { makeStyles } from "@material-ui/styles";
import { ReferenceCheckboxGroupInput } from "ra-friendsofbabba";
import React from "react";

const useStyles = makeStyles((theme) => ({
	checkbox: {
		"& .MuiFormGroup-row": {
			"& .MuiFormControlLabel-root": {
				maxWidth: "49%",
				minWidth: "49%",
			},
		},
	},
	[theme.breakpoints.down("sm")]: {
		checkbox: {
			"& .MuiFormGroup-row": {
				"& .MuiFormControlLabel-root": {
					maxWidth: "100%",
					minWidth: "100%",
				},
			},
		},
	},
}));

const BestReferenceCheckboxGroupInput = (props) => {
	const classes = useStyles();
	return (
		<ReferenceCheckboxGroupInput {...props} className={classes.checkbox} />
	);
};

export default BestReferenceCheckboxGroupInput;
