import { LongTextField } from "ra-friendsofbabba";
import React from "react";

const HtmlField = (props) => (
  <LongTextField
    {...props}
    record={{
      ...props.record,
      [props.source]:
        props.record[props.source] &&
        props.record[props.source].replace(/<[^>]+>/g, " ").trim(),
    }}
  />
);

export default HtmlField;
