import CssBaseline from "@material-ui/core/CssBaseline";
import { LoginPage as FobLoginPage } from "ra-friendsofbabba";
import React from "react";
import theme from "../../theme";
import LoginForm from "../form/LoginForm";

const LoginPage = (props) => (
	<FobLoginPage {...props} themeOverride={theme}>
		<CssBaseline />
		<LoginForm variant="outlined" />
		{/* <ResetPasswordLinkButton href="#/reset-password" /> */}
	</FobLoginPage>
);
export default LoginPage;
