import PropTypes from "prop-types";
import React, { memo, useMemo } from "react";
import { LinearProgress, useQueryWithStore } from "react-admin";
import FilterList from "./FilterList";
import FilterListItem from "./FilterListItem";

const ReferenceFilterList = ({
	resource,
	filter = {},
	sort = {
		field: "name",
		order: "ASC",
	},
	pagination = {
		perPage: 100,
		page: 1,
	},
	label,
	icon: Icon,
	optionValue = "id",
	optionText = "name",
	target,
}) => {
	const { data, loading, total } = useQueryWithStore({
		type: "getList",
		resource,
		payload: { pagination, sort, filter },
	});
	const memoizedData = useMemo(() => data, [data]);
	if (loading) {
		return <LinearProgress />;
	}
	if (total === 0 || !memoizedData) {
		return null;
	}
	return (
		<FilterList
			label={label}
			icon={Icon && <Icon style={{ fontSize: "1.2em" }} />}
		>
			{memoizedData.map((r) => (
				<FilterListItem
					key={r?.[optionValue]}
					label={r?.[optionText]}
					value={{
						[target]: r?.[optionValue],
					}}
				/>
			))}
		</FilterList>
	);
};

ReferenceFilterList.propTypes = {
	resource: PropTypes.string.isRequired,
	filter: PropTypes.object,
	sort: PropTypes.object,
	label: PropTypes.string.isRequired,
	icon: PropTypes.elementType,
	optionValue: PropTypes.string,
	optionText: PropTypes.string,
	target: PropTypes.string.isRequired,
};

export default memo(ReferenceFilterList);
