import * as Icons from '@material-ui/icons';

import {
  AttachmentsList,
  EmployeeCertificatesList,
  EmployeeContractsList,
  EmployeesList,
} from './component/list';

export const resources = [
	{
		name: "employees",
		group: "employees",
		roles: ["admin", "cv", "employee"],
		icon: Icons.People,
		list: EmployeesList,
	},
	{
		name: "cities",
		roles: ["admin"],
		list: null,
	},
	{
		group: "deadlines",
		name: "employee-contracts",
		roles: ["admin", "employee"],
		list: EmployeeContractsList,
		icon: Icons.LibraryBooks,
	},
	{
		name: "employee-jobs",
		roles: ["admin", "employee"],
		list: null,
	},
	{
		group: "deadlines",
		name: "employee-certificates",
		roles: ["admin", "employee"],
		list: EmployeeCertificatesList,
		icon: Icons.VerifiedUser,
	},
	{
		name: "attachments",
		group: "deadlines",
		roles: ["admin"],
		icon: Icons.AttachFile,
		list: AttachmentsList,
	},
	{
		name: "employee-types",
		group: "basic_info",
		roles: ["admin"],
		icon: Icons.Person,
		list: null,
	},
	{
		name: "contracts",
		group: "contracts",
		roles: ["admin"],
		icon: Icons.LibraryBooks,
	},
	{
		name: "contract-types",
		group: "contracts",
		roles: ["admin"],
		icon: Icons.MergeType,
	},
	{
		name: "jobs",
		group: "jobs",
		roles: ["admin"],
		icon: Icons.Work,
	},
	{
		name: "projects",
		group: "jobs",
		roles: ["admin", "employee"],
		icon: Icons.AccountTree,
	},
	// {
	// 	name: "job-roles",
	// 	group: "jobs",
	// 	roles: ["admin"],
	// 	icon: Icons.WorkOutline,
	// },
	{
		name: "services",
		group: "jobs",
		roles: ["admin"],
		icon: Icons.Build,
	},
	{
		name: "workplaces",
		group: "jobs",
		roles: ["admin"],
		icon: Icons.HomeWork,
	},
	{
		name: "langs",
		group: "knowledge",
		roles: ["admin"],
		icon: Icons.Language,
	},
	{
		name: "driving-licenses",
		group: "knowledge",
		roles: ["admin"],
		icon: Icons.DirectionsCar,
	},
	{
		name: "qualifications",
		group: "knowledge",
		roles: ["admin"],
		icon: Icons.School,
	},
	{
		name: "certificates",
		group: "knowledge",
		roles: ["admin"],
		icon: Icons.VerifiedUser,
	},
	{
		name: "work-tools",
		group: "other",
		roles: ["admin"],
		icon: Icons.Build,
	},
	{
		name: "attachment-types",
		group: "other",
		roles: ["admin"],
		icon: Icons.AttachFile,
	},
];
