import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/icons/Link";
import React from "react";
import { TextInput } from "react-admin";
import { useFormState } from "react-final-form";

const LinkUrlInput = ({ ...props }) => {
	const { values } = useFormState({ subscriptions: { values: true } });
	const value = values?.[props.source];
	const baseUrl = values?.[props.baseUrlField];
	const urlExtension = values?.[props.urlExtensionField] || "";

	const endAdornment = () => (
		<InputAdornment position="end">
			<IconButton
				onClick={() => window.open(`${baseUrl || "#"}${value}${urlExtension}`)}
				edge="end"
				aria-label="toggle password visibility"
			>
				<Link />
			</IconButton>
		</InputAdornment>
	);

	return (
		<TextInput
			{...props}
			InputProps={{
				startAdornment: <InputAdornment position="end">/</InputAdornment>,
				endAdornment: value ? endAdornment() : null,
			}}
		/>
	);
};

export default LinkUrlInput;
