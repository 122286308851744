import { DateInput } from "ra-ui-materialui";
import DateTimeInput from "./DateTimeInput";
import React from "react";
import { get } from "lodash";
import { useFormState } from "react-final-form";

const PermitDateTimeInput = ({ ...props }) => {
  const formState = useFormState({ subscription: { values: true } });
  const type = get(formState.values, "type", null);
  if (type === "holidays") {
    return <DateInput {...props} />;
  } else if (type === "permit") {
    return <DateTimeInput {...props} />;
  } else {
    return null;
  }
};

export default PermitDateTimeInput;
