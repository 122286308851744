import { Link } from "@material-ui/core";
import React, { cloneElement, useMemo } from "react";

import { API_URL } from "../../config";
import { Button, TopToolbar } from "ra-ui-materialui";
import GetAppIcon from "@material-ui/icons/GetApp";
import { getToken } from "ra-friendsofbabba";
import { sanitizeListRestProps, useListContext } from "ra-core";

const stopPropagation = (e) => e.stopPropagation();

const ExportButtonBase = ({
  resource,
  filterValues,
  currentSort,
  disabled,
}) => {
  const href = useMemo(() => {
    const filters = Object.keys(filterValues)
      .filter(
        (name) =>
          filterValues[name] !== undefined && filterValues[name] !== null
      )
      .map((name) => `${name}=${filterValues[name]}`)
      .join("&");
    const sort = currentSort
      ? `&sort=${currentSort.field}&direction=${currentSort.direction || "ASC"}`
      : "";
    const href = `${API_URL}/${resource}/?token=${getToken()}&${filters}${sort}&page=1&limit=1000&mode=excel`;
    return href;
  }, [resource, filterValues, currentSort]);
  return (
    <Button
      disabled={disabled}
      component={Link}
      href={href}
      onClick={stopPropagation}
      label="ra.action.export"
    >
      <GetAppIcon />
    </Button>
  );
};

const ExportButtonInList = ({ ...props }) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <ExportButtonBase
        {...props}
        disabled={total === 0}
        currentSort={currentSort}
      />
    </TopToolbar>
  );
};

const ExportReportingButton = ({ mode = "list", ...props }) => {
  switch (mode) {
    case "form":
      return <ExportButtonBase {...props} />;
    case "list":
    default:
      return <ExportButtonInList {...props} />;
  }
};
export default ExportReportingButton;
