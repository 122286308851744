import { Chip, Link, makeStyles } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import classnames from "classnames";
import { get } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {
	sanitizeFieldRestProps,
	TextField,
	useRecordContext,
	useRedirect,
} from "react-admin";

const useStyles = makeStyles((theme) => ({
	link: {
		display: "flex",
		alignItems: "center",
	},
	chip: {},
}));

const ReferenceFilterField = ({
	reference,
	target,
	filterField = "id",
	showIcon = true,
	emptyText = "-",
	component = "Chip",
	...props
}) => {
	const classes = useStyles();
	const { className, source, ...rest } = props;
	const record = useRecordContext(props);
	const value = get(record, source);
	const filter = JSON.stringify({ [filterField]: props.record[target] });
	const url = `/${reference}?filter=${encodeURIComponent(filter)}`;
	const redirect = useRedirect();

	const onClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		redirect(url);
	};

	if (!value || value.length === 0) {
		return (
			<TextField
				{...props}
				record={{
					[source]: emptyText,
				}}
			/>
		);
	}

	if (component === "Link") {
		return (
			<Link
				className={classnames(className, classes.link)}
				onClick={onClick}
				variant="body2"
				{...sanitizeFieldRestProps(rest)}
			>
				{value}
				{showIcon && <ArrowRightIcon />}
			</Link>
		);
	}

	return (
		<Chip
			{...sanitizeFieldRestProps(rest)}
			className={classnames(className, classes.chip)}
			onClick={onClick}
			label={value}
			// size="small"
		/>
	);
};

ReferenceFilterField.propTypes = {
	reference: PropTypes.string.isRequired,
	target: PropTypes.string.isRequired,
};

export default ReferenceFilterField;
