import { Datagrid } from "ra-friendsofbabba";
import React from "react";
import {
	AutocompleteInput,
	DateField,
	Filter,
	List,
	Pagination,
	ReferenceInput,
	SearchInput,
} from "react-admin";
import {
	BoldField,
	CertificateStatusField,
	ReferenceLinkField,
} from "../field";
import { CertificateStatusInput } from "../input";

const Grid = (props) => {
	return (
		<Datagrid {...props}>
			<ReferenceLinkField
				basePath="employees"
				target="employee_id"
				source="employee.fullname"
				label="resources.employee-certificates.fields.employee_id"
				sortBy="Employees.firstname"
			/>
			<BoldField
				source="certificate.name"
				label="resources.employee-certificates.fields.certificate_id"
				sortBy="Certificates.name"
			/>
			<CertificateStatusField
				source="status"
				label="resources.employee-certificates.fields.status"
			/>
			<DateField source="from_date" emptyText="-" sortBy="from_date" />
			<DateField source="due_date" emptyText="-" sortBy="due_date" />
		</Datagrid>
	);
};

const EmployeeCertificatesList = (props) => {
	return (
		<List
			{...props}
			exporter={false}
			sort={{ field: "due_date", order: "ASC" }}
			filters={
				<Filter variant="outlined">
					<SearchInput source="q" alwaysOn />
					<ReferenceInput
						source="employee__id"
						reference="employees"
						alwaysOn
						label="resources.employee-certificates.fields.employee_id"
						perPage={20}
						sort={{
							field: "firstname",
							order: "ASC",
						}}
					>
						<AutocompleteInput optionText="fullname" resettable nullable />
					</ReferenceInput>
					<CertificateStatusInput source="status" alwaysOn />
				</Filter>
			}
			bulkActionButtons={false}
			perPage={50}
			pagination={<Pagination rowsPerPageOptions={[20, 50, 100, 200]} />}
			hasCreate={false}
		>
			<Grid />
		</List>
	);
};

export default EmployeeCertificatesList;
