import './theme.css';

import { grey } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)");
// const type = prefersDarkMode.matches ? "dark" : "light";
const type = "light";
const colors = {
	dark: {
		primary: "#0693e3",
		secondary: grey,
	},
	light: {
		primary: {
			main: process.env?.REACT_APP_PRIMARY_COLOR,
		},
		secondary: {
			main: process.env?.REACT_APP_SECONDARY_COLOR,
		},
	},
};

const theme = createTheme({
	palette: {
		type: type,
		primary: colors[type].primary,
		secondary: colors[type].secondary,
	},
	props: {
		MuiButton: {
			disableElevation: true,
		},
		MuiPaper: {
			variant: "outlined",
		},
	},
	typography: {
		fontFamily: "Be Vietnam Pro",
		fontSize: 15,
	},
	shadows: ["none"],
});

const List = {
	root: {
		"& table td, table th": {
			whiteSpace: "nowrap",
		},
		"& .MuiToolbar-root": {
			whiteSpace: "nowrap",
		},
		"& .MuiToolbar-root > .MuiButtonBase-root": {
			marginLeft: theme.spacing(1),
		},
		"& .MuiTableCell-head": {
			fontWeight: "bold",
		},
	},
	bulkActionsDisplayed: {
		"& .MuiToolbar-regular:first-child": {
			"& .MuiToolbar-root": {
				flexWrap: "wrap",
			},
		},
		[theme.breakpoints.down("sm")]: {
			"& .MuiToolbar-regular:first-child": {
				display: "flex",
				flexDirection: "column",
				flexBasis: "auto",
				height: "auto",
				"& .MuiToolbar-root": {
					background: "inherit",
					padding: 0,
					flexWrap: "wrap",
					justifyContent: "flex-start",
					paddingBottom: theme.spacing(1),
					width: "100%",
				},
				"& div:nth-child(1)": {
					paddingTop: theme.spacing(1),
					paddingBottom: theme.spacing(1),
				},
			},
		},
	},
};

const Form = {
	main: {
		width: "70%",
		minWidth: "960px",
		margin: "1em auto",
		[theme.breakpoints.down("md")]: {
			minWidth: "100%",
			"& .MuiFormControl-root": {
				width: "100%",
			},
		},
		"& p.MuiFormHelperText-root span": {
			display: "none",
		},
		"& .ra-rich-text-input .ql-toolbar *": {
			fontFamily: "Be Vietnam Pro",
		},
		...["h1", "h2", "h3", "h4", "h5", "h6", "p", "li"].reduce((acc, tag) => ({
			...acc,
			[`& .ra-rich-text-input .ql-editor ${tag}`]: {
				fontFamily: "Be Vietnam Pro",
			},
		})),
		"& .ra-rich-text-input .ql-editor li::marker": {
			fontFamily: "Be Vietnam Pro",
			fontSize: "1.5em",
		},
	},
};
const FilterForm = {
	form: {
		"& .filter-field > div:last-child": {
			width: theme.spacing(1),
		},
		"& .filter-field .MuiAutocomplete-root": {
			marginBottom: theme.spacing(0.7),
			minWidth: 250,
		},
		[theme.breakpoints.down("sm")]: {
			"& .filter-field, & .filter-field > .MuiFormControl-root": {
				width: "100%",
			},
		},
	},
};
const TabbedForm = {
	errorTabButton: {
		color: `${theme.palette.error.main} !important`,
	},
};

const Login = {
	main: {
		backgroundColor: "transparent!important",
	},
};

export default createTheme(theme, {
	overrides: {
		RaList: List,
		RaCreate: Form,
		RaEdit: Form,
		RaFilterForm: FilterForm,
		RaTabbedForm: TabbedForm,
		RaLogin: Login,
	},
});
