import React from "react";

import { ReferenceListField } from "ra-friendsofbabba";
import { ChipField, DateField } from "react-admin";

import { Grid } from "@material-ui/core";

import DownloadButton from "../button/DownloadButton";
import { TextFormDivider } from "../form";
import AttachmentStatusField from "./AttachmentStatusField";
import BoldField from "./BoldField";

const EmployeeAttachmentField = ({ className, ...props }) => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={12}>
				<TextFormDivider label="resources.employees.tabs.attachments" />
			</Grid>
			<Grid item xs={12} md={12}>
				<ReferenceListField
					{...props}
					source="attachments"
					reference="attachments"
					target="employee_id"
					createLabel="Aggiungi allegato"
					empty="Nessun allegato registrato"
					sorry="Non puoi aggiungere gli allegati"
					sort={{
						field: "due_date",
						order: "DESC",
					}}
					modify={false}
					label={false}
					fullWidth={true}
					remove={false}
					rowClick="edit"
				>
					<DownloadButton />
					<BoldField source="name" label="resources.attachments.fields.name" />
					<ChipField
						source="attachment_type.name"
						label="resources.attachments.fields.attachment_type_id"
						sortBy="AttachmentTypes.name"
					/>
					<AttachmentStatusField
						source="status"
						label="resources.attachments.fields.status"
					/>
					<DateField
						source="from_date"
						emptyText="-"
						label="resources.attachments.fields.from_date"
					/>
					<DateField
						source="due_date"
						emptyText="-"
						label="resources.attachments.fields.due_date"
					/>
				</ReferenceListField>
			</Grid>
		</Grid>
	);
};

export default EmployeeAttachmentField;
