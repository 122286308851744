import { makeStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import React from "react";
import { ImageField } from "react-admin";
import BoldField from "./BoldField";

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: "left",
	},
	image: {
		"& img": {
			width: (props) => props?.width,
			height: (props) => props?.height,
			objectFit: "cover",
			borderRadius: 10,
			border: `1px solid ${theme.palette.grey[200]}`,
		},
	},
}));

const ImageOrNameField = ({ showName = false, ...props }) => {
	const classes = useStyles(props);
	const image = get(props?.record || {}, props?.source);

	if (!image) {
		return <BoldField {...props} source={props?.nameSource || props?.source} />;
	}

	return (
		<div className={classes.root}>
			{showName && (
				<BoldField
					{...props}
					source={props?.nameSource || props?.source}
					variant="caption"
				/>
			)}
			<ImageField {...props} className={classes.image} />
		</div>
	);
};

export default ImageOrNameField;
