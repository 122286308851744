import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles((theme) => {
	return {
		container: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(2),
		},
		divider: {},
	};
});

const SeparatorInput = (props) => {
	const classes = useStyles();
	return (
		<Box className={classes.container}>
			{props?.label && (
				<Typography
					variant="h6"
					gutterBottom
					style={{ fontWeight: "bold", textTransform: "uppercase" }}
				>
					{props.label}
				</Typography>
			)}
			<Divider className={classes.divider} />
		</Box>
	);
};

export default SeparatorInput;
