import { ChipField, useTranslate } from "react-admin";
import React, { useMemo } from "react";

import { get } from "lodash";

const PermitTypeField = ({ source, record, ...props }) => {
  const translate = useTranslate();
  const value = useMemo(
    () => translate(`resources.user-permits.type.${get(record, source)}`),
    [record, source, translate]
  );
  return <ChipField {...props} record={{ value }} source="value" />;
};
export default PermitTypeField;
