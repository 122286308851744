import {
  CheckboxGroupInput as RaCheckboxGroupInput,
  ReferenceArrayInput,
  usePermissions,
} from "react-admin";
import { Typography, withStyles } from "@material-ui/core";
import { useManyFormatter, useManyParser } from "ra-friendsofbabba";

import React from "react";
import { get } from "lodash";

const optionText = (
  record,
  optionTitle,
  optionSubTitle = undefined,
  minWidth = 250
) => {
  return (
    <Typography
      component="div"
      display="block"
      style={{ minWidth, width: 250 }}
    >
      <Typography variant="subtitle1">{get(record, optionTitle)}</Typography>
      {optionSubTitle && (
        <Typography variant="caption">{get(record, optionSubTitle)}</Typography>
      )}
    </Typography>
  );
};

const Checkboxes = ({
  choices,
  optionTitle,
  optionSubTitle,
  minItemWidth,
  ...props
}) => {
  const { loading, permissions } = usePermissions();

  const isDeveloper = !loading && permissions && permissions(["developer"]);
  const finalChoices = isDeveloper
    ? choices
    : choices.filter((c) => c.code !== "developer");
  return (
    <RaCheckboxGroupInput
      {...props}
      choices={finalChoices}
      optionText={(option) =>
        optionText(option, optionTitle, optionSubTitle, minItemWidth)
      }
    />
  );
};

const CheckboxGroupInput = withStyles((theme) => ({
  root: {
    "& legend": {
      padding: theme.spacing(1),
    },
    "& .MuiFormControlLabel-root": {
      alignItems: "flex-start",
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },
}))(({ classes, ...props }) => {
  const parse = useManyParser();
  const format = useManyFormatter();
  return (
    <ReferenceArrayInput
      {...props}
      format={format}
      parse={parse}
      fullWidth
      className={classes.root}
    >
      <Checkboxes fullWidth />
    </ReferenceArrayInput>
  );
});

export default CheckboxGroupInput;
