import { Datagrid } from "ra-friendsofbabba";
import React from "react";
import {
	AutocompleteInput,
	DateField,
	Filter,
	List,
	NumberField,
	Pagination,
	ReferenceInput,
	SearchInput,
	TextField,
} from "react-admin";
import {
	BoldField,
	ContractStatusField,
	EuroField,
	ReferenceLinkField,
} from "../field";
import { ContractStatusInput } from "../input";

const Grid = (props) => {
	return (
		<Datagrid {...props}>
			<ReferenceLinkField
				basePath="employees"
				target="employee_id"
				source="employee.fullname"
				label="resources.employee-contracts.fields.employee_id"
				sortBy="Employees.firstname"
			/>
			<BoldField
				source="name"
				label="resources.employee-contracts.fields.contract_id"
				sortBy="Contracts.name"
			/>
			<ContractStatusField source="status" />
			<TextField source="level" />
			<NumberField source="weekly_hours" />
			<EuroField
				source="hourly_cost"
				style={{ width: "100%", textAlign: "center", display: "block" }}
			/>
			<EuroField
				source="yearly_cost"
				style={{ width: "100%", textAlign: "center", display: "block" }}
				sortable={false}
			/>
			<DateField source="from_date" />
			<DateField source="due_date" emptyText="-" />
		</Datagrid>
	);
};

const EmployeeContractsList = (props) => {
	return (
		<List
			{...props}
			exporter={false}
			sort={{ field: "due_date", order: "ASC" }}
			filters={
				<Filter variant="outlined">
					<SearchInput source="q" alwaysOn />
					<ReferenceInput
						source="employee__id"
						reference="employees"
						alwaysOn
						label="resources.employee-contracts.fields.employee_id"
						perPage={20}
						sort={{
							field: "firstname",
							order: "ASC",
						}}
					>
						<AutocompleteInput optionText="fullname" resettable nullable />
					</ReferenceInput>
					<ContractStatusInput source="status" alwaysOn />
				</Filter>
			}
			bulkActionButtons={false}
			perPage={50}
			pagination={<Pagination rowsPerPageOptions={[20, 50, 100, 200]} />}
			hasCreate={false}
		>
			<Grid />
		</List>
	);
};

export default EmployeeContractsList;
