import { Link } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { getToken } from "ra-friendsofbabba";
import React from "react";
import { Button } from "react-admin";
import { API_URL } from "../../config";

const DownloadButton = ({
	basePath = "",
	label = "ra.action.download",
	record,
	icon = <GetAppIcon />,
	resource,
	...props
}) => {
	return (
		<Button
			component={Link}
			href={`${API_URL}/${resource}/${record?.id}/download?token=${getToken()}`}
			label={label}
			target="_blank"
			onClick={(e) => {
				e.stopPropagation();
			}}
			{...props}
		>
			{icon}
		</Button>
	);
};

export default DownloadButton;
