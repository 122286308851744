import React from "react";
import { TextField } from "react-admin";
import { get } from "lodash";

const TicketIdField = ({ record, ...props }) => (
  <TextField
    {...props}
    source="id"
    record={{
      id: `${get(record, "number")}/${get(record, "year")}`,
    }}
  />
);

export default TicketIdField;
