import React from "react";

import { NumberField } from "react-admin";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	free: {
		color: theme.palette.success.main,
		fontWeight: "bold",
	},
	not_free: {
		color: theme.palette.error.main,
		fontWeight: "bold",
	},
}));

const FreeWeeklyHours = ({ record, source, ...props }) => {
	const value = record?.[source] || 0;
	const classes = useStyles();
	const className = value > 0 ? classes.free : classes.not_free;

	return (
		<NumberField
			record={record}
			source={source}
			{...props}
			className={className}
		/>
	);
};

export default FreeWeeklyHours;
