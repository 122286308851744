import React from 'react';

import { Layout as FobLayout } from 'ra-friendsofbabba';

import logoIlSognoDelCapitano from '../assets/logo-ilsognodelcapitano.png';
import logoLce from '../assets/logo-lce.png';
import logoPuzzle from '../assets/logo-puzzle.png';
import { DRAWER_WIDTH } from '../config';
import AppBar from './AppBar';
import Menu from './Menu';

const logos = {
	puzzle: {
		src: logoPuzzle,
		height: 45,
		style: {},
	},
	lce: {
		src: logoLce,
		height: 58,
		style: {},
	},
	ilsognodelcapitano: {
		src: logoIlSognoDelCapitano,
		height: 68,
		style: {
			marginTop: 16,
		},
	},
};

const logo = logos[process.env?.REACT_APP_ID] || logos.puzzle;

const Layout = (props) => (
	<FobLayout
		{...props}
		drawerWidth={
			process.env?.REACT_APP_DRAWER_WIDTH
				? parseInt(process.env?.REACT_APP_DRAWER_WIDTH)
				: DRAWER_WIDTH
		}
		logo={
			<img
				src={logo.src}
				height={logo.height}
				style={logo.style}
				alt={process.env?.REACT_APP_TITLE}
			/>
		}
		menu={Menu}
		appBar={AppBar}
		title={null}
		subTitle={null}
	/>
);
export default Layout;
