import {
  AutocompleteArrayInput,
  useManyFormatter,
  useManyParser,
} from "ra-friendsofbabba";

import React from "react";
import { ReferenceArrayInput } from "react-admin";

const TicketAssignmentsInput = (props) => {
  const manyFormatter = useManyFormatter();
  const manyParser = useManyParser();
  return (
    <ReferenceArrayInput
      {...props}
      reference="collaborators"
      filter={{ role_ids: [5, 6] }}
      format={manyFormatter}
      parse={manyParser}
      fullWidth
    >
      <AutocompleteArrayInput optionText="name" resource={props.resource} />
    </ReferenceArrayInput>
  );
};
export default TicketAssignmentsInput;
