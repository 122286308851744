import React from "react";
import { SelectInput } from "react-admin";

const TIME_VIEW_MODES = ["auto", "days", "hours", "minutes"].map((mode) => ({
  id: mode,
  name: `app.time.view_mode.${mode}`,
}));

const TimeViewModeInput = (props) => {
  return (
    <SelectInput
      {...props}
      choices={TIME_VIEW_MODES}
      label="ra.time.view_mode"
      defaultValue={"auto"}
    />
  );
};
export default TimeViewModeInput;
