import { Typography } from "@material-ui/core";
import React from "react";
import { NumberField } from "react-admin";

const EuroField = (props) => {
	const value = props?.record?.[props?.source];
	return (
		<Typography component="span" variant="body2" {...props}>
			{value} €
		</Typography>
	);
};

EuroField.propTypes = NumberField.propTypes;

export default EuroField;
