import { ReferenceSelectInput } from "ra-friendsofbabba";
import React from "react";

const ProjectTaskInput = (props) => {
  return (
    <ReferenceSelectInput
      {...props}
      reference="project-tasks"
      optionText="name"
    />
  );
};
export default ProjectTaskInput;
