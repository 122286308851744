import React from "react";
import { SelectInput } from "react-admin";

const ContractStatusInput = (props) => {
	return (
		<SelectInput
			{...props}
			emptyText="ra.action.all"
			choices={[
				{
					id: "active",
					name: "resources.employee-contracts.fields.status.active",
				},
				{
					id: "ending",
					name: "resources.employee-contracts.fields.status.ending",
				},
				{ id: "end", name: "resources.employee-contracts.fields.status.end" },
			]}
		/>
	);
};

ContractStatusInput.defaultProps = SelectInput.defaultProps;
ContractStatusInput.propTypes = SelectInput.propTypes;

export default ContractStatusInput;
