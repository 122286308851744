import React from "react";
import { NumberInput } from "react-admin";

const EuroInput = (props) => {
	return (
		<NumberInput
			{...props}
			InputProps={{
				endAdornment: "€",
			}}
		/>
	);
};

EuroInput.propTypes = NumberInput.propTypes;
EuroInput.defaultProps = NumberInput.defaultProps;

export default EuroInput;
