import { ResetPasswordPage as FobResetPasswordPage } from "ra-friendsofbabba";
import { RECAPTCHA_SITE_KEY } from "../../config";
import React from "react";
import theme from "../../theme";

const ResetPasswordPage = (props) => (
  <FobResetPasswordPage
    {...props}
    themeOverride={theme}
    recaptchaSiteApiKey={RECAPTCHA_SITE_KEY}
  />
);

export default ResetPasswordPage;
