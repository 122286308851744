import React from "react";
import { ReferenceAutocompleteInput } from "ra-friendsofbabba";

const CollaboratorInput = (props) => {
  return (
    <ReferenceAutocompleteInput
      {...props}
      reference="collaborators"
      optionText="name"
      sort={{ field: "UserProfiles.name", order: "ASC" }}
    />
  );
};
export default CollaboratorInput;
