import { ReferenceAutocompleteInput } from "ra-friendsofbabba";
import React, { useMemo } from "react";
import { get } from "lodash";
import { usePermissions, useQuery } from "react-admin";

const ProjectFilteredInput = (props) => {
  const { loaded, permissions } = usePermissions();
  const isClient = !useMemo(
    () => loaded && permissions(["manager", "employee", "admin", "notifiable"]),
    [loaded, permissions]
  );
  const { data, loading } = useQuery({
    type: "get",
    resource: "projects/filter",
  });
  const projects = get(data, "data", null);

  if (isClient && (projects?.length === 1 || loading)) {
    return null;
  }

  return (
    <ReferenceAutocompleteInput
      {...props}
      reference="projects"
      optionText="name"
    />
  );
};
export default ProjectFilteredInput;
