import { Grid } from "@material-ui/core";
import React from "react";
import {
	FormDataConsumer,
	ReferenceInput,
	required,
	SelectInput,
	TextInput,
} from "react-admin";
import { useFormState } from "react-final-form";

const EmployeeLangsInput = ({ className, ...props }) => {
	const form = useFormState()?.values;
	const langs = form?.employee_langs || [];
	const langIds = langs
		.map((lang) => lang?.lang_id)
		.filter((langId) => langId !== undefined);
	return (
		<FormDataConsumer {...props}>
			{({ getSource, scopedFormData }) => {
				return (
					<Grid container spacing={1}>
						<Grid item xs={12} sm={3}>
							<ReferenceInput
								{...props}
								label="resources.employee-langs.fields.lang_id"
								source={getSource("lang_id")}
								reference="langs"
								filter={{
									_ids: langIds,
								}}
								sort={{
									field: "name",
									order: "ASC",
								}}
								isRequired={true}
								validate={required()}
								fullWidth
							>
								<SelectInput />
							</ReferenceInput>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextInput
								{...props}
								label="resources.employee-langs.fields.write_level"
								isRequired={true}
								source={getSource("write_level")}
								validate={required()}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextInput
								{...props}
								label="resources.employee-langs.fields.read_level"
								source={getSource("read_level")}
								isRequired={true}
								validate={required()}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextInput
								{...props}
								label="resources.employee-langs.fields.talk_level"
								isRequired={true}
								source={getSource("talk_level")}
								validate={required()}
								fullWidth
							/>
						</Grid>
					</Grid>
				);
			}}
		</FormDataConsumer>
	);
};

export default EmployeeLangsInput;
