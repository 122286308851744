import React from "react";

import { ReferenceListField } from "ra-friendsofbabba";
import {
	DateField,
	FormTab,
	NumberField,
	required,
	SimpleForm,
	TabbedForm,
	TextField,
	TextInput,
	useRecordContext,
	useRedirect,
} from "react-admin";

import {
	BoldField,
	CertificateStatusField,
	ContractStatusField,
	EmployeeAttachmentField,
	EuroField,
} from "../field";
import { AwesomeReferenceArrayInput, UserBasicInfoInput } from "../input";

const EmployeesForm = (props) => {
	const create = props?.record?.id ? false : true;
	const redirect = useRedirect();
	const record = useRecordContext(props);

	if (create || record?.employee_type_id === 2) {
		return (
			<SimpleForm {...props} redirect="list">
				<UserBasicInfoInput fullWidth={true} />
				<EmployeeAttachmentField />
			</SimpleForm>
		);
	}

	return (
		<TabbedForm {...props} redirect="list">
			<FormTab label="resources.employees.tabs.basic_info">
				<UserBasicInfoInput fullWidth={true} />
			</FormTab>
			<FormTab label="resources.employees.tabs.contracts">
				<ReferenceListField
					source="employee_contracts"
					reference="employee-contracts"
					target="employee_id"
					createLabel="Aggiungi contratto di lavoro"
					empty="Nessun contratto di lavoro registrato"
					sorry="Non puoi aggiungere contratti di lavoro"
					sort={{
						field: "due_date",
						order: "DESC",
					}}
					rowClick="edit"
					modify={false}
					label={false}
					remove={false}
					fullWidth={true}
				>
					<BoldField
						source="name"
						label="resources.employee-contracts.fields.contract_id"
					/>
					<ContractStatusField source="status" />
					<TextField source="level" />
					<NumberField source="weekly_hours" />
					<EuroField
						source="hourly_cost"
						style={{ width: "100%", textAlign: "center", display: "block" }}
					/>
					<EuroField
						source="yearly_cost"
						style={{ width: "100%", textAlign: "center", display: "block" }}
						sortable={false}
					/>
					<DateField source="from_date" />
					<DateField source="due_date" emptyText="-" />
				</ReferenceListField>
			</FormTab>
			<FormTab label="resources.employees.tabs.jobs">
				<ReferenceListField
					source="employee_jobs"
					reference="employee-jobs"
					target="employee_id"
					createLabel="Aggiungi mansione"
					empty="Nessuna mansione di lavoro registrata"
					sorry="Non puoi aggiungere le mansioni di lavoro"
					sort={{
						field: "weekly_hours",
						order: "DESC",
					}}
					rowClick="edit"
					modify={false}
					label={false}
					remove={false}
					fullWidth={true}
				>
					<BoldField
						source="name"
						label="resources.employee-jobs.fields.name"
					/>
					<TextField
						source="project.name"
						label="resources.employee-jobs.fields.project_id"
						emptyText="-"
					/>
					<NumberField source="weekly_hours" />
					<EuroField
						source="hourly_cost"
						style={{ width: "100%", textAlign: "center", display: "block" }}
					/>
					<EuroField
						source="yearly_cost"
						style={{ width: "100%", textAlign: "center", display: "block" }}
						sortable={false}
					/>
				</ReferenceListField>
			</FormTab>
			<FormTab label="resources.employees.tabs.certificates">
				<ReferenceListField
					source="employee_certificates"
					reference="employee-certificates"
					target="employee_id"
					createLabel="Aggiungi certificato"
					empty="Nessun certificato registrato"
					sorry="Non puoi aggiungere i certificati"
					sort={{
						field: "due_date",
						order: "DESC",
					}}
					rowClick={(a, b, r) => {
						if (!r?.employee_certificate?.employee_id) {
							const _record = encodeURIComponent(
								JSON.stringify({
									id: undefined,
									employee_id: props?.record?.id,
									certificate_id: r?.id,
								})
							);
							redirect(`/employee-certificates/create?source=${_record}`);
						} else {
							redirect(
								"edit",
								`/employee-certificates`,
								r?.employee_certificate?.id
							);
						}
					}}
					create={false}
					modify={false}
					label={false}
					remove={false}
					fullWidth={true}
				>
					<BoldField
						source="name"
						label="resources.employee-certificates.fields.certificate_id"
						sortBy="Certificates.name"
					/>
					<CertificateStatusField
						source="employee_certificate.status"
						label="resources.employee-certificates.fields.status"
					/>
					<DateField
						source="employee_certificate.from_date"
						emptyText="-"
						label="resources.employee-certificates.fields.from_date"
						sortBy="from_date"
					/>
					<DateField
						source="employee_certificate.due_date"
						emptyText="-"
						label="resources.employee-certificates.fields.due_date"
						sortBy="due_date"
					/>
				</ReferenceListField>
			</FormTab>
			<FormTab label="resources.employees.tabs.attachments">
				<EmployeeAttachmentField />
			</FormTab>
			<FormTab label="menu.items.work-tools">
				<AwesomeReferenceArrayInput
					{...props}
					source="work_tools"
					reference="work-tools"
					label="resources.employees.fields.work-tools"
					sort={{
						field: "name",
						order: "ASC",
					}}
					emptyText="-"
					fullWidth={true}
				>
					<TextInput source="name" validate={required()} fullWidth />
				</AwesomeReferenceArrayInput>
			</FormTab>
		</TabbedForm>
	);
};

export default EmployeesForm;
