import React, { useCallback, useEffect, useState } from "react";

import { TextField } from "@material-ui/core";
import { get } from "lodash";
import { useDataProvider, useRefresh } from "react-admin";

const RecordAndRefreshInput = ({
	source,
	record,
	resource,
	minWidth = 150,
	...props
}) => {
	const refresh = useRefresh();
	const [value, setValue] = useState(get(record, source, ""));
	const [updating, setUpdating] = useState(false);
	const handleChange = useCallback((evt) => setValue(evt.target.value), []);
	const dataProvider = useDataProvider();

	const handleKeyPress = useCallback(
		(evt) => {
			if (evt.key !== "Enter") {
				return;
			}
			setUpdating(true);
			dataProvider
				.update(resource, {
					id: record.id,
					data: {
						// ...record,
						[source]: value,
						_changed: source,
					},
				})
				.then(() => {
					setUpdating(false);
					refresh();
				});
		},
		[value, dataProvider, resource, record, source, refresh]
	);
	const handleBlur = useCallback(() => {
		if (get(record, source) === value) {
			return;
		}
		setUpdating(true);
		dataProvider
			.update(resource, {
				id: record.id,
				data: {
					// ...record,
					[source]: value,
					_changed: source,
				},
			})
			.then(() => {
				setUpdating(false);
				refresh();
			});
	}, [value, dataProvider, resource, source, record, refresh]);
	useEffect(() => setValue(get(record, source)), [record, source]);

	return (
		<TextField
			{...props}
			label={null}
			value={value}
			disabled={updating}
			onChange={handleChange}
			onKeyPress={handleKeyPress}
			onBlur={handleBlur}
			style={{ minWidth, width: "100%" }}
			multiline
			InputProps={{ "arial-label": "naked" }}
		/>
	);
};

export default RecordAndRefreshInput;
