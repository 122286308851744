import {
	IconButton,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import matches from "lodash/matches";
import pickBy from "lodash/pickBy";
import * as React from "react";
import { isValidElement, memo } from "react";
import { useListFilterContext, useTranslate } from "react-admin";
import { shallowEqual } from "react-redux";

const useStyles = makeStyles(
	(theme) => ({
		listItem: {
			paddingLeft: "1.8em",
		},
		listItemText: {
			margin: 0,
		},
	}),
	{ name: "RaFilterListItem" }
);

const FilterListItem = (props) => {
	const { label, value } = props;
	const { filterValues, setFilters } = useListFilterContext();
	const translate = useTranslate();
	const classes = useStyles(props);

	const isSelected = matches(
		pickBy(value, (val) => typeof val !== "undefined")
	)(filterValues);

	const addFilter = () => {
		setFilters({ ...filterValues, ...value }, null, false);
	};

	const removeFilter = () => {
		const keysToRemove = Object.keys(value);
		const filters = Object.keys(filterValues).reduce(
			(acc, key) =>
				keysToRemove.includes(key) ? acc : { ...acc, [key]: filterValues[key] },
			{}
		);

		setFilters(filters, null, false);
	};

	const toggleFilter = () => (isSelected ? removeFilter() : addFilter());

	return (
		<ListItem
			button
			onClick={toggleFilter}
			selected={isSelected}
			className={classes.listItem}
		>
			<ListItemText
				primary={isValidElement(label) ? label : translate(label, { _: label })}
				className={classes.listItemText}
				data-selected={isSelected ? "true" : "false"}
			/>
			{isSelected && (
				<ListItemSecondaryAction>
					<IconButton size="small" onClick={toggleFilter}>
						<CancelIcon />
					</IconButton>
				</ListItemSecondaryAction>
			)}
		</ListItem>
	);
};

const arePropsEqual = (prevProps, nextProps) =>
	prevProps.label === nextProps.label &&
	shallowEqual(prevProps.value, nextProps.value);

export default memo(FilterListItem, arePropsEqual);
