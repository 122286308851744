import React from "react";
import { AutocompleteInput, Filter, ReferenceInput, SearchInput } from "react-admin";

const EmployeesFilter = (props) => {
	return (
		<Filter {...props} variant="outlined">
			<SearchInput source="q" alwaysOn />
			<ReferenceInput
				label="resources.employees.fields.work_experiences"
				source="has_workexperience"
				reference="jobs"
				alwaysOn
				sort={{
					field: "name",
					order: "ASC"
				}}
				perPage={50}
			>
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
			<ReferenceInput
				label="resources.employees.fields.qualifications"
				source="has_qualification"
				reference="qualifications"
				alwaysOn
				sort={{
					field: "name",
					order: "ASC"
				}}
				perPage={50}
			>
				<AutocompleteInput optionText="name" />
			</ReferenceInput>
		</Filter>
	);
};

export default EmployeesFilter;
