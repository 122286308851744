import { makeStyles } from "@material-ui/core";
import React from "react";
import { Show, SimpleShowLayout } from "react-admin";
import EmployeesShow from "../show/EmployeesShow";

const useStyles = makeStyles((theme) => ({
	main: {
		marginBottom: theme.spacing(4),
	},
}));

const EmployeesListExpand = (props) => {
	const classes = useStyles();
	return (
		<Show
			classes={classes}
			{...props}
			/* disable the app title change when shown */
			title=" "
			// hasEdit={false}
		>
			<SimpleShowLayout>
				<EmployeesShow />
			</SimpleShowLayout>
		</Show>
	);
};

export default EmployeesListExpand;
