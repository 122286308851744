import { Divider, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
	separator: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		marginLeft: "auto",
		marginRight: "auto",
	},
}));

const FormDivider = () => {
	const classes = useStyles();
	return <Divider className={classes.separator} />;
};

export default FormDivider;
