import React from "react";
import { useFormState } from "react-final-form";
import { NumberInput, useQuery } from "react-admin";
import { get } from "lodash";

const RemainingTimeInput = (props) => {
  const formState = useFormState();
  const declared_date = get(formState, "values.declared_date", null);
  const { data } = useQuery({
    type: "get",
    resource: "activities/remaining-time",
    payload: { date: declared_date },
  });

  const remainingTime = get(data, "data.remaining_time", null);
  return <NumberInput {...props} initialValue={remainingTime} />;
};
export default RemainingTimeInput;
