import { InputAdornment } from "@material-ui/core";
import React from "react";
import { Button, TextInput, useNotify } from "react-admin";
import CopyToClipboard from "react-copy-to-clipboard";
import { useFormState } from "react-final-form";

const ClipboardTextInput = ({ source, ...props }) => {
	const notify = useNotify();
	const { values } = useFormState({ subscriptions: { values: true } });
	const value = values?.[props.source];
	const baseUrl = values?.[props.baseUrlField];
	const urlPrefix = values?.[props.urlPrefixField] || "";
	const urlExtension = values?.[props.urlExtensionField] || "";
	const copiableValue = `${baseUrl || "#"}${urlPrefix}/${value}${urlExtension}`;

	return (
		<TextInput
			{...props}
			source={source}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<CopyToClipboard
							text={copiableValue}
							onCopy={() => notify("ra.action.content_copied")}
							disabled={!copiableValue}
						>
							<Button onClick={() => {}} label="ra.action.copy" />
						</CopyToClipboard>
					</InputAdornment>
				),
			}}
		/>
	);
};

export default ClipboardTextInput;
