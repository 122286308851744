import React from "react";
import { SelectInput } from "react-admin";

const BooleanSelectInput = (props) => {
	return (
		<SelectInput
			{...props}
			choices={[
				{ id: true, name: "ra.action.yes" },
				{ id: false, name: "ra.action.no" },
			]}
			emptyText="ra.action.all"
		/>
	);
};

BooleanSelectInput.propTypes = SelectInput.propTypes;

export default BooleanSelectInput;
