import { Confirm, useMutation, useNotify, useTranslate } from "react-admin";
import React, { Fragment, useState } from "react";

import { Button } from "@material-ui/core";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { get } from "lodash";

const ResetButton = ({ record }) => {
  const notify = useNotify();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const [handleConfirm, { loading }] = useMutation(
    {
      type: "post",
      resource: "users/reset",
      payload: {
        body: {
          account: get(record, "email"),
        },
      },
    },
    {
      onSuccess: () => {
        setOpen(false);
        notify("resources.users.messages.reset_success", "info");
      },
      onFailure: ({ message }) => {
        setOpen(false);
        notify("resources.users.messages.reset_failure", "error");
      },
    }
  );
  const handleClick = () => setOpen(true);
  const handleCancel = () => setOpen(false);
  const id = get(record, "id", 0);
  if (id === 0) {
    return null;
  }
  return (
    <Fragment>
      <Confirm
        isOpen={open}
        loading={loading}
        title="resources.users.messages.reset_title"
        content="resources.users.messages.reset_content"
        onConfirm={handleConfirm}
        onClose={handleCancel}
      />
      <Button
        onClick={handleClick}
        color="primary"
        startIcon={<RotateLeftIcon />}
      >
        {translate("resources.users.buttons.reset")}
      </Button>
    </Fragment>
  );
};
export default ResetButton;
