import React, { useMemo } from "react";

import { ReferenceSelectInput } from "ra-friendsofbabba";
import { useFormState } from "react-final-form";

const ReferenceSelectWithDepsInput = ({ deps, ...props }) => {
  const { values } = useFormState({ subscription: { values: true } });

  const filter = useMemo(() => {
    return deps.reduce((acc, dep) => {
      if (values[dep]) {
        acc[dep] = values[dep];
      }
      return acc;
    }, {});
  }, [deps, values]);
  return <ReferenceSelectInput {...props} filter={filter} />;
};
export default ReferenceSelectWithDepsInput;
