import { Link } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { sanitizeFieldRestProps, useRedirect } from "react-admin";

const ReferenceLinkField = ({ basePath, target, ...rest }) => {
	const redirect = useRedirect();
	const value = get(rest?.record, rest.source);
	const handleClick = (e) => {
		e.stopPropagation();
		e.preventDefault();
		redirect("edit", basePath || rest?.resource, get(rest?.record, target));
	};
	return (
		<Link
			onClick={handleClick}
			variant="body2"
			{...sanitizeFieldRestProps(rest)}
		>
			{value}
		</Link>
	);
};

export default ReferenceLinkField;
