import React from "react";
import { ReferenceAutocompleteInput } from "ra-friendsofbabba";

const ProjectInput = ({ sort, ...props }) => {
  return (
    <ReferenceAutocompleteInput
      {...props}
      reference="projects"
      optionText="name"
      fullWidth
      sort={sort || { field: "smart", order: "DESC" }}
    />
  );
};
export default ProjectInput;
