import React, { useMemo } from "react";
import TimeField from "./TimeField";
import { Typography, makeStyles } from "@material-ui/core";

import { grey, red } from "@material-ui/core/colors";
import classNames from "classnames";
import useDayInfo from "./useDayInfo";
import { HolidayIcon } from "../../icons";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  day: {
    maxWidth: 50,
  },
  saturday: {
    padding: theme.spacing(3, 2, 3, 2),
    backgroundColor: grey[500],
  },
  sunday: {
    padding: theme.spacing(3, 2, 3, 2),
    backgroundColor: red[900],
  },
  holiday: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  permit: {
    verticalAlign: "middle",
    textDecoration: "underline",
    "& svg": {
      width: 16,
      height: 16,
    },
  },
}));

const DayField = ({ record, source }) => {
  const classes = useStyles();
  const { holiday, weekday, isWeekend } = useDayInfo({ record, source });
  const className = useMemo(() => {
    const classesWeekend = { 6: classes.saturday, 7: classes.sunday };
    return classNames(
      classes.day,
      source !== "total" && classesWeekend[weekday]
    );
  }, [classes, source, weekday]);

  if (holiday > 0 && !isWeekend) {
    return <HolidayIcon className={classes.holiday} />;
  }
  return (
    <div className={classes.root}>
      <Typography variant="caption" noWrap className={classNames(className)}>
        <TimeField
          source={source}
          record={record}
          blank
          short
          variant="caption"
        />
        <br />
        <TimeField
          source={`${source}_permit`}
          record={record}
          blank
          short
          permit
          variant="caption"
          className={classes.permit}
        />
      </Typography>
    </div>
  );
};
export default DayField;
