import React, { useMemo } from "react";
import { get } from "lodash";
import { Typography } from "@material-ui/core";
import { useListContext, useTranslate } from "react-admin";
import { PermitIcon } from "../../icons";

function getTime(minutes, limit, timeViewMode, type) {
  var value = minutes / limit;
  var format = `ra.time_format.${timeViewMode}${type}`;

  if (value % 1 !== 0) {
    value = value.toFixed(2);
  }
  return { value, format };
}

const TimeField = ({
  permit = false,
  short = false,
  blank = false,
  record,
  source,
  basePath,
  resource,
  ...props
}) => {
  const translate = useTranslate();
  const { filterValues } = useListContext();
  const { minutes, type, timeViewMode } = useMemo(() => {
    const minutes =
      !record?.id && filterValues?.sub && source !== "total"
        ? parseInt(get(record, source, 0)) / 2
        : parseInt(get(record, source, 0));
    const type = short ? ".short" : "";
    const timeViewMode = get(filterValues, "timeViewMode", "auto");
    return { minutes, type, timeViewMode };
  }, [record, source, filterValues, short]);

  if (minutes === 0 && permit === true) {
    return null;
  }
  if (minutes === 0 && blank === true) {
    return "-";
  }
  let time;

  if (timeViewMode === "days" || (minutes >= 480 && timeViewMode === "auto")) {
    time = getTime(minutes, 480, "days", type);
  } else if (
    timeViewMode === "hours" ||
    (minutes >= 60 && timeViewMode === "auto")
  ) {
    time = getTime(minutes, 60, "hours", type);
  } else if (
    timeViewMode === "minutes" ||
    (minutes < 60 && timeViewMode === "auto")
  ) {
    time = getTime(minutes, 1, "minutes", type);
  }

  return (
    <Typography {...props}>
      {time?.value} {translate(time?.format)} {permit && <PermitIcon />}
    </Typography>
  );
};
export default TimeField;
